import type { Mediator } from 'client/types/common'
import type { ReportParameter } from 'server/db/report-parameter-db'
import type { ReportParameterValue } from 'server/db/report-parameter-value-db'
import type { Parameter, Report as ClientReport } from '@isoftdata/svelte-report-viewer'
import type { Report as ServerReport } from 'server/db/report-db'
import { ParameterType, Editability } from '@isoftdata/svelte-report-viewer'
import pProps from 'p-props'

function formatParameterType(type: string): ParameterType {
	switch (type) {
		// case 'String':
		// 	return ParameterType.STRING
		case 'SQL Clause':
			return ParameterType.SQL_CLAUSE
		case 'Number':
			return ParameterType.NUMBER
		case 'Choice':
			return ParameterType.CHOICE
		case 'Key Choice':
			return ParameterType.KEY_CHOICE
		case 'DateTime':
			return ParameterType.DATE_TIME
		case 'Date':
			return ParameterType.DATE
		case 'Time':
			return ParameterType.TIME
		case 'Boolean':
			return ParameterType.BOOLEAN
		case 'Currency':
			return ParameterType.CURRENCY
		default:
			// return ParameterType.STRING
			return ParameterType.NUMBER
	}
}

function formatEditability(editability: string) {
	switch (editability) {
		case 'Readonly':
			return Editability.READONLY
		case 'Limited':
			return Editability.LIMITED
		case 'Unrestricted':
			return Editability.UNRESTRICTED
		default:
			return Editability.UNRESTRICTED
	}
}

export async function getParameterValuesForReport(mediator: Mediator, selectedReportId: number, parameterValueOverrides?: Record<string, string> | null): Promise<Array<Parameter>> {
	const { parameters: rawParameters, parameterValues } = (await pProps({
		parameters: mediator.call('emitToServer', 'load report parameter list', { reportId: selectedReportId, runParameterQueries: true }),
		parameterValues: mediator.call('emitToServer', 'load report parameter value list', { reportId: selectedReportId }),
	})) as { parameters: Array<ReportParameter>; parameterValues: Array<ReportParameterValue> }

	const parameters: Array<Parameter> = rawParameters.map(param => {
		const parameter = serverParameterToClientParameter(param)
		if (parameterValueOverrides && parameterValueOverrides[param.name]) {
			parameter.value = parameterValueOverrides[param.name]
		}
		return parameter
	})

	if (parameterValues.length) {
		// Put the parameter values (per parameter) into an object for (Key) Choice parameters, and find the default value for all parameters
		const parameterOptions = parameterValues.reduce((acc: Record<number, { options: string[]; value?: string }>, parameter) => {
			const { defaultValue: isDefault, value, reportParameterId } = parameter
			const parameterIndex = parameters.findIndex(parameter => parameter.id === reportParameterId)

			if (!acc[reportParameterId]) {
				acc[reportParameterId] = {
					options: [],
				}
			}
			if (isDefault) {
				acc[reportParameterId].value = value
			}
			// Only need to add the options if it's a (Key) Choice parameter
			if (value && (parameters[parameterIndex].type === ParameterType.CHOICE || parameters[parameterIndex].type === ParameterType.KEY_CHOICE)) {
				acc[reportParameterId].options.push(value)
			}
			return acc
		}, {})
		// Set the parameter values into the proper parameters
		Object.entries(parameterOptions).forEach(([parameterIdStr, { options, value }]) => {
			const reportParameterId = parseInt(parameterIdStr)
			const parameterIndex = parameters.findIndex(parameter => parameter.id === reportParameterId)

			if (parameterIndex > -1 && (parameters[parameterIndex].type === ParameterType.CHOICE || parameters[parameterIndex].type === ParameterType.KEY_CHOICE)) {
				parameters[parameterIndex].options = (parameters[parameterIndex].options ? parameters[parameterIndex].options.concat(options) : options) as string[]
			}

			if (typeof value === 'string') {
				parameters[parameterIndex].value = value
			}
		})
	}

	return parameters
}
export async function generatePdfPreview(
	mediator: Mediator,
	{
		name,
		type,
		parameters,
	}: {
		name: string
		type: string
		parameters: Record<string, string>
	}
) {
	try {
		const preview = await mediator.call(
			'emitToServer',
			'get crystal report pdf',
			{ name, type, parameters },
			{
				context: JSON.stringify(
					{
						name,
						type,
						parameters,
					},
					null,
					4
				),
				doNotAlert: true,
			}
		)
		return preview as {
			data: string
			mimeType: string
		}
	} catch (err) {
		console.error(err)
		throw err
	}
}

export function serverReportToClientReport(serverReport: ServerReport): ClientReport {
	return {
		...serverReport,
		id: serverReport.reportId,
		averageExecutionTime: serverReport.averageExecutionTime ?? 0,
		numberOfParameters: 0, // todo?
		description: serverReport.description ?? '',
	}
}

export function serverParameterToClientParameter(serverParam: ReportParameter): Parameter {
	const options = serverParam.options ?? []
	const type = formatParameterType(serverParam.type)

	if (type === ParameterType.CHOICE) {
		const stringOptions = options.map(option => option.label).filter(Boolean)

		return {
			...serverParam,
			id: serverParam.reportParameterId,
			type,
			rank: serverParam.rank ?? 0,
			options: stringOptions,
			editability: formatEditability(serverParam.editability),
			value: serverParam.value && stringOptions.includes(serverParam.value) ? serverParam.value : '',
		}
	}

	return {
		...serverParam,
		id: serverParam.reportParameterId,
		type,
		rank: serverParam.rank ?? 0,
		options,
		editability: formatEditability(serverParam.editability),
		value: serverParam.value ?? '',
	}
}
