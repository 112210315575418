import type { ReportQueueWithParameters } from 'server/db/report-queue-db'
import type { AppContext, ResolveParameters } from 'types/common'
import type { PrintJob } from '@isoftdata/svelte-report-viewer'
import component from './History.svelte'
import { stringToBoolean } from '@isoftdata/utility-string'

type StateParameters = {
	showSentJobs: boolean
	showCancelledJobs: boolean
	pageNumber: number
	sortDirection: 'ASC' | 'DESC'
	sortColumnProperty: string
}

export default function ({ mediator, stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.report-viewer.history',
		route: 'history',
		defaultParameters: {
			showSentJobs: 'false',
			showCancelledJobs: 'false',
		},
		querystringParameters: ['showSentJobs', 'showCancelledJobs', 'lastRefreshTime'],
		template: {
			svelte: true,
			component,
		},
		async resolve(_data, parameters: ResolveParameters<StateParameters>) {
			const showSentJobs = stringToBoolean(parameters.showSentJobs)
			const showCancelledJobs = stringToBoolean(parameters.showCancelledJobs)

			let statusFilter = ['READY', 'FAILED']

			if (showSentJobs) {
				statusFilter.push('SENT')
			}

			if (showCancelledJobs) {
				statusFilter.push('CANCELLED')
			}

			const history = (await mediator.call('emitToServer', 'load report queue job history', {
				status: statusFilter,
				includeParameters: true,
			})) as Array<ReportQueueWithParameters>

			return {
				history: history.map(
					(job): PrintJob => ({
						...job,
						id: job.reportQueueId,
						destination: job.destination ?? '',
						status: job.status.toUpperCase(),
						executionTime: Number(job.executionTime?.toFixed(2)) ?? null,
					})
				),
				showSentJobs,
				showCancelledJobs,
			}
		},
	})
}
