import type { AppContext, ResolveParameters } from 'types/common'
import type { Report as ServerReport } from 'server/db/report-db'

import component from './Reports.svelte'
import { serverReportToClientReport } from 'utility/report-viewer-helper'

type StateParameters = {
	selectedReportId: number | null
	selectedCategory: string | null
}

export default function ({ mediator, stateRouter }: AppContext) {
	stateRouter.addState({
		name: 'app.report-viewer.reports',
		route: 'reports',
		defaultChild: 'preview',
		template: {
			svelte: true,
			component,
			options: {},
		},
		async resolve(_data, stateParameters: ResolveParameters<StateParameters>) {
			const selectedReportId = parseInt(stateParameters.selectedReportId, 10) || null

			const reportList = ((await mediator.call('emitToServer', 'load reports', { userVisible: true, includeAvgTime: true })) as Array<ServerReport>).map(serverReportToClientReport)
			// If they pass a category and report id that's not in the list, load and add it to the list so they can preview it.
			if ((stateParameters.selectedCategory || stateParameters.selectedCategory === '') && selectedReportId) {
				const missingReport = reportList.findIndex(report => report.id === selectedReportId) === -1
				if (missingReport) {
					const fetchedReport = ((await mediator.call('emitToServer', 'load reports', { reportId: stateParameters.selectedReportId })) as Array<ServerReport>)?.[0]
					if (fetchedReport) {
						reportList.push(serverReportToClientReport(fetchedReport))
					}
				}
			}
			return { reportList, selectedReportId, selectedCategory: stateParameters.selectedCategory }
		},
	})
}
