<script lang="ts">
	import type { SvelteAsr } from 'client/types/common'
	import type { Mediator } from 'types/common'
	import type { ComponentEvents } from 'svelte'
	import { History, type PrintJob } from '@isoftdata/svelte-report-viewer'
	import { getContext } from 'svelte'

	export let asr: SvelteAsr

	export let history: Array<PrintJob>
	export let showCancelledJobs: boolean
	export let showSentJobs: boolean

	const mediator = getContext<Mediator>('mediator')

	function onPreviewClick(event: CustomEvent<PrintJob>) {
		localStorage.setItem('reportViewerPreviewPrintJob', JSON.stringify(event.detail))
		asr.go('app.report-viewer.reports.preview', { selectedReportId: event.detail.reportId, preview: true })
	}

	function setStatus(reportQueueId: number | Array<number>, status: 'READY' | 'CANCELLED') {
		mediator.call('emitToServer', 'set report queue job status', {
			reportQueueId,
			status,
		})
	}

	function onShowCancelledChange(event: CustomEvent<boolean>) {
		asr.go(null, { showCancelledJobs: event.detail }, { inherit: true })
	}

	function onShowSentChange(event: CustomEvent<boolean>) {
		asr.go(null, { showSentJobs: event.detail }, { inherit: true })
	}
</script>

<History
	{history}
	{showSentJobs}
	{showCancelledJobs}
	refreshHref={asr.makePath(null, { lastRefreshTime: Date.now(), pageNumber: 1 }, { inherit: true })}
	on:previewClick={onPreviewClick}
	on:setReadyClick={event => setStatus(event.detail.id, 'READY')}
	on:cancelClick={event => setStatus(event.detail, 'CANCELLED')}
	on:showCancelledChange={onShowCancelledChange}
	on:showSentChange={onShowSentChange}
></History>
